//import jwtDecode from "jwt-decode";

// const initialState = {
//     best_selling_products: [],
//     menu: [],
//     new_products: [],
//     sections: [],
//     special_offer_products: [],
//     sliders: []
// }
export const ui = (state = {}, action) => {
    switch (action.type) {
        case "UI": {
            return {
                category_parent: action.payload.category_parent,
                best_selling_products: action.payload.best_selling_products,
                contact_us: action.payload.contact_us,
                menu: action.payload.menu,
                new_products: action.payload.new_products,
                sections: action.payload.sections,
                special_offer_products: action.payload.special_offer_products,
                sliders: action.payload.sliders,
                articles: action.payload.articles
            }
        }
        default: {
            return state
        }
    }
}
